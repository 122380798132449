import Image from "next/image";
import cloudflareCustomImageLoader from "../../../../../Scripts/cloudflareCustomImageLoader";
import { bucketURLBuilder } from "../../../../../utils/bucket";
import { bannerSizes } from "../../../../../utils/imgUtils";

interface PropTypes {
  desktopFilePath: string;
  altText: string;
  link?: string;

}


const BannerImage = (props: PropTypes) => {
  return (
    <div className="w-full self-stretch rounded-[8px] min-h-48 md:min-h-[400px] relative">
      <Image
        className="w-full h-auto object-cover"      
        loader={cloudflareCustomImageLoader}
        src={bucketURLBuilder(props.desktopFilePath)}
        layout="fill"
        objectFit="contain"        
        alt={props.altText || ""}
        sizes={bannerSizes()}
        priority={true}
        onClick={(ev) => {
          if (props.link) {
            window.open(props.link)
          }
        }}
      />


    </div>
  );
};

export default BannerImage;
