import { useRouter } from "next/router";
import * as storeApi from "../../../../api/store"
import * as storeQueryType from "../../../../api/store/storeQueryTypes"
import { useEffect, useState } from "react";
import { useClient } from "../../../../hooks/useClientState";
import { useRootApp } from "../../../../hooks/useRootAppState/useRootAppState";
import { ServiceType } from "../../../../hooks/useActiveStore/useActiveStore";


export interface Options {
    channelType: "WEBSITE" | "MOBILE"
    initialData?: storeQueryType.Result
    location?: storeQueryType.Location

}


interface State {
    mode: "initial-loading" | "loaded" | "query-loading"
    data?: storeQueryType.Result
}

const cleanString = (str: string) => {
    return str.replace(/['".,\/#!$%\^&\*;:{}=\-_`~()]/g, "")
        .toLowerCase()
        .trim();
}


const useStoreLocator = (opts: Options) => {
    const [state, setState] = useState<State>({ mode: !!opts.initialData ? "loaded" : "initial-loading", data: opts.initialData })
    const router = useRouter()
    const cdata = useClient()
    const rootApp = useRootApp()
    const cart = rootApp.cart;



    const storeIcon = cdata.data.clientMeta?.styleJson?.locationIcon || ""

    const loadDefault = async () => {
        console.log("@loadDefault")

        const resp = await storeApi.getAllStores()
        if (resp?.status !== 200) {
            return
        }



        setData(resp?.data)
    }

    const setData = (data: storeQueryType.Result) => {

        if (data.options.location) {
            data.stores = data.stores.sort((x, y) => (x.distance || 10000) - (y.distance || 10000))
        } else {
            data.stores = data.stores.sort((x, y) => {

                const xname = cleanString(x.name)
                const yname = cleanString(y.name)

                return xname.localeCompare(yname, 'en', { sensitivity: 'base' })
            });

        }


        setState({ mode: "loaded", data: data })
    }


    const loadWithFilters = async (queryfilters: any[]) => {
        console.log("@loadWithFilters")
        const options = state.data?.options || {
            queryfilters: queryfilters,
        }


        const resp = await storeApi.queryStores({ ...options, channelType: opts.channelType, queryfilters: queryfilters })
        if (resp?.status !== 200) {
            return
        }


        setData(resp?.data)
    }

    const loadWithServiceType = async (serviceType?: string) => {
        console.log("@loadWithServiceType")

        const options = state.data?.options || {
            queryfilters: [],
        }



        const resp = await storeApi.queryStores({ ...options, channelType: opts.channelType, serviceType })
        if (resp?.status !== 200) {
            return
        }

        setData(resp?.data)
    }

    const loadWithLocation = async (location: storeQueryType.Location) => {
        console.log("@loadWithLocation", location)

        const options = state.data?.options || {
            queryfilters: [],
        }


        const resp = await storeApi.queryStores({ ...options, channelType: opts.channelType, location })
        if (resp?.status !== 200) {
            return
        }

        setData(resp?.data)


    }

    const selectStore = async (store: storeQueryType.StoreInfo, serviceType: ServiceType ) => {
        rootApp.selectStore(store, serviceType)
    }

    const load = async () => {
        if (opts.location && opts.location.lat && opts.location.lng) {
            await loadWithLocation(opts.location)
        } else {
            await loadDefault()
        }
    }


    useEffect(() => {
        load()

    }, [])



    return { state, storeIcon, loadWithFilters, loadWithServiceType, loadWithLocation, selectStore }
}


export type Handle = ReturnType<typeof useStoreLocator>


export default useStoreLocator;