import type { NextPage } from "next";
import Homepage from "../../containers/main/Homepage";
import config from "../../config/app";
import { commonISRApi } from "../../state/commonApiService";

const Home: NextPage = (props: any) => {
  let data = [];

  if (!props.notFound) {
    data = props.bannerImage;
  }

  return (
    // <div className={styles.container}>
    // </div>
    (<Homepage data={data} />)
  );
};


interface Context {
  params: {
    host: string;
  }
}

export async function getStaticProps(context: Context) {
  
  console.log("@context", context);

  const urlBanner = config.ECommerce.BASE_URL + "/clientImages/home";
  const urlCustomer = config.ECommerce.BASE_URL + "/client";
  try {
    const bannerImage = await commonISRApi("GET", urlBanner);
    const configData = await commonISRApi("GET", urlCustomer);

    if (!bannerImage || !configData) {
      return { props: { notFound: true } };
    } else {
      return { props: { bannerImage, configData }, revalidate: 15 };
    }
  } catch (error: any) {
    return { props: { notFound: true } };
  }
}

export async function getStaticPaths() {
  return {
    paths: ["romans", "barcelos"].map((host) => ({ params: { host } })),
    fallback: "blocking",
  };
}

export default Home;
